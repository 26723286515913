import React from 'react'
import strip from '../components/assets/images/strip.jpg'

const Content = () => {
    const words = "Car Servicing, Car Repairs, Air Conditioning, Clutch Replacements, Car Batteries, MOT Repairs, Engine Repair & Rebuilds, Engine Tuning, Car Body Repairs, Dent Removal, Tyres Exhausts, Brakes, Gearbox Repairs, Engine Diagnostics, Breakdown Recovery, Car Valeting, Tow Bar Fitting";
    const colors = ["red", "blue", "green", "orange", "purple", "white", "brown", "cocoa", "black", "teal", "navy", "taupe", "red", "coral", "indigo", "violet", "orange"];

    const wordsArray = words.split(", ");
    
    

  return (
    <div className='main-content'>
        <h1>
        MW Car And Commercial
        </h1>
        <p>
        If you’re looking for a reliable car garage in North Wales and Chester, look no further than MW Car and Commercial, specialists in a wide range of garage services including Car Servicing, MOT, Car Repairs, Engine Diagnostics and Tyres in North Wales and Chester.
<br />Established in 2011, we provide an honest and reliable service at all times, proud to be recognised as a highly reputable car garage.
<br /><br/>Located at Unit 5 Broughton Industrial Estate, Broughton Mills Road, we are ideally based to serve the local North Wales and Chester community, undertaking all types of car garage services, from Car Servicing to MOT.
        </p>
        <h1>
        Why Choose MW Car and Commercial?
        </h1>
        <p>
        At MW Car and Commercial, we have worked hard to build a reputation for offering a trusted and “value for money” service to all our customers in North Wales and Chester. <br />We understand the importance of good customer service, with the majority of our work gained through positive word of mouth and repeat custom.
        </p>
        <h1>
        Qualified & Experienced Car Mechanics

        </h1>
        <p>
        Our mechanics are fully qualified and experienced in working with all types of vehicles, completing all work to the highest of standards at a very competitive price. Our mechanics are on hand to offer free, valuable advice on Car Servicing, MOT, and Car Repairs, and are happy to answer any questions you may have regarding your vehicle.
<br/><br />When working on your car, we will clearly explain all the options available to you, along with our personal recommendations. At MW Car and Commercial, we always offer a quote upfront and always make sure we obtain your approval prior to any work being carried out.
<br /><br />We understand the frustration caused by not having your car, which is why our car mechanics work efficiently to get your car back on the road in next to no time.

        </p>
        <h1 className='service-list'>
        {wordsArray.map((word, i) => (
        <span key={i} style={{ color: colors[i % colors.length] }}>
          {word+"-"}
        </span>
      ))}
        </h1>
        <img src={strip} alt='strip' style={{width:'80%', margin:'auto'}}/>
    </div>
  )
}

export default Content