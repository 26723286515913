import React from 'react'

const InfoBlock = ({heading, image, content}) => {
  return (
    <div className='block-container'>
        <div className='block-heading'>{heading}</div>
        <img src={image}  alt='MOTs' className='block-image'/>
        <p className='block-content'>{content}</p>
    </div>
  )
}

export default InfoBlock