import React from 'react'
import facebook from '../components/assets/images/facebook-mw-car-and-commercial.png'

const BusinessHours = () => {

  const handleExternalLink=()=>{
    window.location.replace('https://www.facebook.com/MWCarandcommercial')
}

  return (
    <div className='business-hours'>
        <h1>Business Hours</h1>
        <ul>
  <li><span className="day">Monday:</span> <span className="times">09:00-17:00</span></li>
  <li><span className="day">Tuesday:</span> <span className="times">09:00-17:00</span></li>
  <li><span className="day">Wednesday:</span> <span className="times">09:00-17:00</span></li>
  <li><span className="day">Thursday:</span> <span className="times">09:00-17:00</span></li>
  <li><span className="day">Friday:</span> <span className="times">09:00-17:00</span></li>
  <li><span className="day">Saturday:</span> <span>Closed</span></li>
  <li><span className="day">Sunday:</span> <span>Closed</span></li>
</ul>
<ul>
    <h2>Address</h2>
    <li>Unit 5 Broughton Industrial Estate</li>
    <li>Broughton Mills Road</li>
    <li>North Wales and Chester</li>
    <li>CH4 0BY</li>
</ul>
<ul>
    <h2>Call us</h2>
    <li>01244 660999</li>
    
</ul>
<img src={facebook} alt='facebook MW Car and Commercial'  className="facebook-btn" onClick={handleExternalLink} />
    </div>
  )
}

export default BusinessHours