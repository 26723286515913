import "./App.css";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import ImageSlider from "./components/ImageSlider";
import {slides} from '../src/components/assets/Slides'
import Manufacturers from "./components/Manufacturers";
import InfoBlock from "./components/InfoBlock";
import repair from "../src/components/assets/images/repair.jpg"
import mot from "../src/components/assets/images/mot.jpg"
import servicing from "../src/components/assets/images/servicing.jpg"
import BuzzText from "./components/BuzzText";

function App() {
  return (
    <div className="App">
      <Header />
      <ImageSlider slides={slides}/>
      <BuzzText />
      <div className="blocks-container">
      <InfoBlock 
        heading='MOT' 
        image={mot} 
        content='MOTs in North Wales and Chester are a legal requirement. Every year, cars over three years old need to have a check.'
      />
      <InfoBlock
        heading={'Car Repairs'}
        image={repair}
        content='Get your car repaired by our experienced mechanics and get back on the road in no time!'
      />
      <InfoBlock 
        heading={'Car Servicing'}
        image={servicing}
        content='While an MOT will make sure your car is roadworthy, servicing it will make sure the car will last.'
      />
      </div>
      <Manufacturers />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
