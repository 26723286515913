import React, { useState, useEffect } from 'react';


const ImageSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
  });

  return (
    <div className="image-slider">
      <div className="image-slider-container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`image-slider-item ${
              index === currentSlide ? "active" : ""
            }`}
          >
            <img src={slide.image} alt={slide.alt} width="100%" height="100%" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;