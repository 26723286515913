import React from 'react'
import map from '../components/assets/images/mwcardirections.png'
//<a href="contact-car-garage-North Wales and Chester"><img alt="Click Here To View A Larger Map" src="https://maps.google.com/maps/api/staticmap?zoom=15&amp;size=220x120&amp;maptype=roadmap&amp;sensor=false&amp;language=&amp;markers=color:0xBCC0C3|CH4 0BY&amp;center=CH4 0BY&amp;key=AIzaSyA2zHL7bNBgtSP5_8gxQlMKNgnnG7uFO3c"/></a>
const Map = () => {

    const handleExternalLink=()=>{
        window.location.replace('https://goo.gl/maps/p5PbmRXb1VhRJAgR7')
    }
  return (
    <div className='map'>
        <h1>Directions</h1>
        <img src={map} alt='MW Car and Commercial directions' onClick={handleExternalLink}/>
    </div>
  )
}

export default Map