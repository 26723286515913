import engine from '../assets/images/engine.jpg'
import returnkey from '../assets/images/return.jpg'
import diagnostics from '../assets/images/diagnostics.jpg'

export const slides = [
    {
      image: engine,
      alt: 'MW Car and Commercial Engine Repairs',
    },
    {
      image: returnkey,
      alt: 'MW Car and Commercial Service',
    },
    {
        image: diagnostics,
        alt: 'MW Car and Commercial Siagnotics',
      },
    
  ];