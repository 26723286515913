import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios'


function EmailForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [status, setStatus] = useState('')
  
  const onSubmit = data =>{ 
    setStatus("sending your email...")
    console.log(data);
    axios.post(process.env.REACT_APP_EMAILSERVER,data)
    .then(response =>{
      setStatus(response.data)
      console.log(response)
    })
    .catch((err)=>{
      console.log(errors)
    })
  }



  return (
    <>
    
    <form 
        className='email-form'
        onSubmit={handleSubmit(onSubmit)}>
            <h1>Contact</h1>
            
      <input type="text" title="your first name" placeholder="First name" {...register("Firstname", {required: true, maxLength: 80})} />
      <input type="text" title="your last name" placeholder="Last name" {...register("Lastname", {required: true, maxLength: 50})} />
      <input type="text" title ="your email address"placeholder="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
      <input type="tel" title="your phones number" placeholder="Phone number" {...register("Phonenumber", {required: true, minLength: 6, maxLength: 12})} />
      <input type="text" title="your car registration plate number" placeholder="Reg number" {...register("Regnumber", {minLength: 4, maxLength: 8})} />
      <input title="subject of enquiry" type="text" placeholder="Subject" {...register("Subject", {required: true, maxLength: 100})} />
      <textarea title="your question / enquiry" {...register("Question", {required: true})} />
      <input title="click to send email" className='email-submit-btn' type="submit" />
      {status}
    </form>
    </>
  );
}
export default EmailForm